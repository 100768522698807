<template>
    <div>
        <a-modal
            :visible="contactModal.type === 'Contact' && contactModal.visible"
            @cancel="onClose"
            @ok="onClose"
            :footer="null"
            :bodyStyle="{padding: '30px'}"
            width="800px"
            centered
            >
            <BHLoading :show="loading" />
            <h5 class="mb-5">Add New Lead</h5>

            <a-form-model ref="ruleForm" :model="contact" :rules="rules">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="First Name" prop="firstName">
                            <a-input v-model="contact.firstName" size="large" placeholder="Enter First Name" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Last Name" prop="lastName">
                            <a-input v-model="contact.lastName" size="large" placeholder="Enter Last Name" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="Email" prop="email">
                            <a-input v-model="contact.email" size="large" placeholder="Enter Email" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Phone" prop="phone">
                            <a-input v-model="contact.phone" size="large" placeholder="Enter Phone Number" />
                        </a-form-model-item>
                    </a-col>
				</a-row>
				<a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="Lead Status" prop="leadStatus">
                            <a-select v-model="contact.leadStatus" placeholder="Select one" size="large">
                                <div slot="dropdownRender" slot-scope="menu">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0;" />
                                    <div
                                        style="padding: 8px; cursor: pointer; color:var(--orange)"
                                        @mousedown="e => e.preventDefault()"
                                        @click="addNewStatus"
                                    >
                                        <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Sales Status
                                    </div>
                                </div>
                                <a-select-option v-for="stat in Object.values(leadStatus)" :key="stat.id" :value="stat.id">{{stat.name}}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Source" prop="source">
                            <a-select v-model="contact.source" placeholder="Select one" size="large">
                                <div slot="dropdownRender" slot-scope="menu">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0;" />
                                    <div
                                        style="padding: 8px; cursor: pointer; color:var(--orange)"
                                        @mousedown="e => e.preventDefault()"
                                        @click="addNewSource"
                                    >
                                        <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Source
                                    </div>
                                </div>
                                <a-select-option v-for="source in sources" :key="source.id" :value="source.id">{{source.name}}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-model-item label="Tags" prop="tags">
                            <a-select v-model="contact.tags" mode="tags" style="width: 100%" placeholder="Add tag" size="large">
                                <a-select-option v-for="(tag,tagI) in tags" :value="tag.name" :key="tag.name + tagI">
                                    {{tag.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-model-item label="Description" prop="description">
                            <a-textarea
                                size="large"
                                v-model="contact.description"
                                :auto-size="{ minRows: 3, maxRows: 5 }"
                                placeholder="Add description"
                                />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-model-item label="Visibility" prop="visibility">
                            <a-select @change="changeVisibility" v-model="contact.visibility" style="width: 100%;" size="large">
                                <a-select-option value="everyone">
                                    Everyone
                                </a-select-option>
                                <a-select-option value="owners">
                                    Only Sales Owner(s)
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24" v-if="users && users.length && contactModal.visible">
                        <a-form-model-item label="Sales Owner(s)" prop="owners"  >
                            <a-select v-model="contact.owners" placeholder="Select one" size="large" mode="multiple" @search="searchOwner" :filter-option="filterOption">
                                <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">{{`${user.firstName} ${user.lastName}`}}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div style="width:100%; text-align: right;">
                <a-button size="large" @click="onClose" class="mt-3 mr-3 cancel-button" style="width: 125px;">CANCEL</a-button>
                <a-button size="large" @click="onSubmit" style="width: 125px;" type="primary">CREATE</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'

export default {
    components:{
        BHLoading,
        VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data() {
        return {
            type:'',
            userSource:[],
            contact:{
                firstName:'',
                lastName:'',
                email:'',
                phone:'',
                leadScore:0,
                address:'',
                city:'',
                region:'',
                country:'Select a country',
                instance:'',
                tasks:[],
                tags:[],
                newTags:[],
                subscribed:[],

                middleName:'',
                prefix:'',
                suffix:'',
                website:'',
                social:[
                    {
                        key:'LinkedIn',
                        value:'',
                    }
                ],
                source:'nosource',
                leadStatus:'',
                postal:'',
                jobTitle:'',
                company:'',
                visibility:'everyone',
                owners:[],
                description:'',
            },

            loading:false,

            social: [
                'LinkedIn',
                'Twitter',
                'Google+',
                'Facebook',
                'YouTube',
                'Instagram',
                'Pinterest',
                'WeChat',
                'Quora',
                'Foursquare',
                'Klout',
                'Gravatar',
                'Other'
            ],

            rules: {
                firstName: [
                    { required: true, message: 'Please input a first name', trigger: 'blur' },
                ],
                lastName: [
                    { required: true, message: 'Please input a last name', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: 'Please enter an email', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: 'Please enter a phone number', trigger: 'blur' },
                ],
                owners:[
                    { required: true, message: 'Please select one', trigger: 'blur' },
                ]
            },
        }
    },
    watch:{
        contactModal:{
            handler(val) {
                if (val.visible) {
                    this.contact.owners.push(this.owner.id)
                    this.userSource = [this.owner]
                } else {
                    this.contact.owners = []
                    this.userSource = []
                }
            }
        }
    },
    computed:{
        sources() {
            if (this.$store.state.contacts?.allSettings?.app?.options?.leadSource?.length) {
                return this.$store.state.contacts.allSettings.app.options.leadSource
            } else {
                return [
                    {name:'No Source', id:'nosource'},
                    {name:'Email', id:'email'},
                    {name:'Cold Call', id:'coldcall'},
                    {name:'Advertising', id:'advertising'}
                ]
            }
        },
        leadStatus() {
            return this.$store.state.contacts.statuses
        },
        users() {
            if (!this.$store.state.contacts.users || !this.$store.state.contacts.users.length) {
                return [this.$store.state.user.user]
            } else {
                return this.$store.state.contacts.users
            }
        },
        contacts(){
            return Object.values(this.$store.state.contacts.allContacts)
        },
        totalContacts(){
            return this.$store.state.contacts.totalContacts
        },
        contactModal(){
            return this.$store.state.addNew
        },
        fields(){
          return Object.values(this.$store.state.contacts.fields)
        },
        tags(){
          return this.$store.state.contacts.tags
        },
        currentPage:{
            get(){
                return this.$store.state.appData.currentPage
            },
            set(val){
                this.$store.commit('SET_CONTACTS_PAGE', val)
            }
        },
        instance(){
            return this.$store.state.instance
        },
        loadingPage:{
            get(){
                return this.$store.state.appData.loadingPage
            },
            set(val){
                this.$store.commit('LOAD_PAGE',val)
            }
        },
        owner() {
            return this.$store.state.user.user
        }
    },
    methods:{
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        searchOwner(input){
            if(input && input.length>1) {
                this.users.forEach(x => {
                    x.name = x.firstName+ ' ' + x.lastName
                })
                this.userSource = this.users.filter(({name}) => {
                    name = name.toLowerCase()
                    input = input.toLowerCase()
                    return name.includes(input)
                })
            } else {
                return this.userSource = []
            }
        },
        addNewSource() {
            this.type = 'leadSource'
            this.$store.commit('OPEN_LEAD_STATUS', {type:'leadSource'})
        },
        addNewStatus() {
            this.type = 'leadStatus'
            this.$store.commit('OPEN_LEAD_STATUS', {type:'leadStatus'})
        },
        req:msg=>({required:true,message:msg}),
        changeVisibility(e) {
            if (e == 'owners') {
                // this.contact.owners = this.users.map(x => x = x.id)
                this.userSource = this.users
            } else if (e == 'everyone') {
                // this.contact.owners = [this.owner.id]
                let ids = this.userSource.map(x => x = x.id)
                if (!ids.includes(this.owner.id)) {
                    this.userSource.push(this.owner)
                }
            }
        },
        onClose({type='',data={}}){
			if(this.$refs['ruleForm']){
				this.$refs['ruleForm'].resetFields();
			}
            this.$store.commit('CLOSE_ADD_NEW')
            this.$store.dispatch('CLOSE_CONTACT_DRAWER',{type,data})
        },
        package(){
            let sendObj = JSON.parse(JSON.stringify(this.contact))
            if (sendObj.leadStatus == '') sendObj.leadStatus = null
            if (sendObj.status === 'bh_lead') sendObj.status = null
            return sendObj
        },
        tagsToIds(obj){

            let tags = obj.tags || []

            obj.newTags = []
            let oldTags = []

            let self = this

            tags.forEach(tID => {
            if (Object.values(self.tags).find(x => x.name === tID)) {
                let find = Object.values(self.tags).find(x => x.name == tID)
                oldTags.push(find.id)
            }
            else obj.newTags.push(tID)
            })
            obj.tags = oldTags

            return obj

        },
        onSubmit(){

            this.$refs.ruleForm.validate(async valid => {

                if (valid) {
                    let sendObj = this.package()
                    sendObj = this.tagsToIds(sendObj)
                    this.loading = true
                    this.$api.post(`/contacts/${this.instance.id}`, sendObj).then( ({data}) => {
                        data.tags.forEach(tag => {
                            if (!this.tags[tag.id]) {
                                this.$store.commit('SET_PROP', {where:['tags',tag.id],what:tag} )
                            }
                        })
                        this.loading = false
                        this.$store.commit('SET_TOTAL_RESULT', this.totalContacts + 1)
                        if (this.contacts.length < 10){
                            this.onClose({type:'add',data})
                        } else {
							if(this.$refs['ruleForm']){
								this.$refs['ruleForm'].resetFields();
							}
                            this.$store.commit('CLOSE_ADD_NEW')
                        }
                    }).catch(error => {
						console.error('ERROR FROM ADD NEW CONTACT', error)
                        this.$message.error('Lead already exists with this email address. Update lead to apply change(s).')
                        if(this.$refs['ruleForm']){
							this.$refs['ruleForm'].resetFields();
						}
                        this.loading = false
                        this.$store.commit('CLOSE_ADD_NEW')
                    })
                } else {
                    return false;
                }
            });

        },
    },
    created(){

    },
    mounted(){

    }
}
</script>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .select-options{

    }
    .cancel-button.ant-btn {
        border-color:#ECE9F1 !important;
        background-color:#ECE9F1;
        color:#3F3356
    }
</style>

<style lang="scss" scoped>
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
</style>
