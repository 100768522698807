<template>
    <div>
        <PreviewEmailModal @closemodal="onClose"/>
        <a-modal
            :visible="emailModal.type === 'Email' && emailModal.visible"
            @cancel="onClose"
            :footer="null"
            :bodyStyle="{padding: '30px'}"
            width="700px"
            :zIndex="1000"
            centered>
            <BHLoading :show="loading" />
            <div>
                <h5 class="mb-4">Add New Message</h5>
                <a-form-model ref="newEmail" :model="newEmail">
                    <a-row :gutter="16">
                        <template v-if="!addTextTemplate">
							<a-col v-if="nylasAccount && nylasAccount.email" :span="24">
                                <a-form-model-item>
									<slot name="label">
										From <span style="color: var(--danger);">*</span>
									</slot>
                                    <a-input :default-value="nylasAccount.email" v-model="nylasAccount.email" size="large" disabled />
                                </a-form-model-item>
                            </a-col>
                            <a-col v-if="leadEmail == '' && !bulkEmail.length" :span="24">
                                <a-form-model-item label="To" required prop="to" :rules="req('Please select a contact')">
                                    <searchContact :multiple="true" v-if="emailModal.type === 'Email' && emailModal.visible" style="width:100%" @updatecontact="updateContact"/>
                                </a-form-model-item>
                            </a-col>
                            <a-col v-else :span="24">
                                <div class="dF aC" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                                    <div class="dF mr-3"><div>To</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                    <i class="fe fe-lock" />
                                </div>
                                <a-form-model-item v-if="defaultName != ''">
                                    <a-input :default-value="defaultName" size="large" disabled />
                                </a-form-model-item>
                            </a-col>
							<a-col :span="24">
                                <a-form-model-item label="CC" prop="cc">
                                    <a-select
										v-model="newEmail.cc"
										mode="tags"
										size="large"
										style="width: 100%"
										placeholder="Ex. email@address.com"
										show-search
										:show-arrow="false"
										:filter-option="false"
										@search="suggestContact"
										:not-found-content="null"
										:options="queriedContacts"
										>
									</a-select>
                                </a-form-model-item>
                            </a-col>
							<a-col :span="24">
                                <a-form-model-item label="BCC" prop="bcc">
                                    <a-select
										v-model="newEmail.bcc"
										mode="tags"
										size="large"
										style="width: 100%"
										placeholder="Ex. email@address.com"
										show-search
										:show-arrow="false"
										:filter-option="false"
										@search="suggestContact"
										:not-found-content="null"
										:options="queriedContacts"
										>
									</a-select>
                                </a-form-model-item>
                            </a-col>
                        </template>

                        <a-col :span="24">
                            <a-form-model-item label="Subject" required prop="subject" :rules="req('Please enter a subject for this email')">
                                <a-input size="large" v-model="newEmail.subject" />
                            </a-form-model-item>
                        </a-col>
						<a-col :span="24" v-if="!addTextTemplate">
							<a-form-model-item label="Show Email Signature" prop="showSignature">
								<a-switch v-model="newEmail.showSignature"></a-switch>
							</a-form-model-item>
						</a-col>
                        <a-col :span="24">
                            <a-form-model-item v-if="emailModal.type === 'Email' && emailModal.visible" required>
								<slot name="label">
									Message<span style="color: var(--danger);">*</span> - <span style="color: var(--primary); font-weight: bold;"><i>Type @ to view list of variables</i></span>
								</slot>
                                <Mentions :showMentions="bulkEmail.length ? false:true" :message="storeMessage" :template="!addTextTemplate ? true : false" :addTemplate="addTextTemplate ? true : false" @saveTemplate="saveTemplate" @close="onClose" @previewEmail="previewEmail" class="task__comment" :templates="templates" @loadTemplate="loadTemplate" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <!-- <div class="dF" style="justify-content:flex-end">
                <a-button type="secondary" size="large" class="mr-3" >DISCARD</a-button>
                <a-button type="primary" size="large">PREVIEW</a-button>
            </div> -->
        </a-modal>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import searchContact from '@/components/common/searchContact'
import Mentions from '@/components/common/Mentions'
import PreviewEmailModal from '@/components/contacts/PreviewEmailModal'
export default {
    components:{
        BHLoading, searchContact, Mentions, PreviewEmailModal
    },
    watch:{
        visible:{
            async handler(val) {
                if (val) {
                    if (!this.bulkEmail.length && this.emailModal.type == 'Email' && this.leadEmail != '') {
                        console.log('after val')
                        if (this.allContacts[this.leadEmail]) {
                            console.log('runnn name')
                            if (this.allContacts[this.leadEmail].firstName && this.allContacts[this.leadEmail].lastName && this.allContacts[this.leadEmail].firstName.trim() && this.allContacts[this.leadEmail].lastName.trim()) {
                                this.defaultName = `${this.allContacts[this.leadEmail].firstName} ${this.allContacts[this.leadEmail].lastName} (${this.allContacts[this.leadEmail].email})`
                            } else {
                                console.log('run else')
                                this.defaultName = `${this.allContacts[this.leadEmail].email}`
                            }
                        } else if(this.externalContacts[this.leadEmail]) {
                            if (this.externalContacts[this.leadEmail].firstName && this.externalContacts[this.leadEmail].lastName && this.externalContacts[this.leadEmail].firstName.trim() && this.externalContacts[this.leadEmail].lastName.trim()) {
                                this.defaultName = `${this.externalContacts[this.leadEmail].firstName} ${this.externalContacts[this.leadEmail].lastName} (${this.externalContacts[this.leadEmail].email})`
                            } else {
                                this.defaultName = `${this.externalContacts[this.leadEmail].email}`
                            }
                        } else {
                            console.log('doesnt include')
                            this.loading = true
                            await this.$api.get(`/contacts/:instance/${this.leadEmail}`).then(({data}) => {
                                if (data.firstName && data.lastName && data.firstName.trim() && data.lastName.trim()) this.defaultName = `${data.firstName} ${data.lastName} (${data.email})`
                                else this.defaultName = `${data.email}`
                                this.$store.commit('ADD_EXTERNAL_CONTACT', data)
                                this.loading = false
                            }).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                        this.newEmail.to = this.leadEmail
                    } else if (this.emailModal.type == 'Email' && this.leadEmail == '' && this.bulkEmail.length) {
                        this.bulkEmail.forEach((id, index) => {
                            if (this.allContacts[id]) {
                                if (this.allContacts[id].firstName && this.allContacts[id].lastName && this.allContacts[id].firstName.trim() && this.allContacts[id].lastName.trim()) {
                                    if (index != this.bulkEmail.length-1) {
                                        this.defaultName = this.defaultName + `${this.allContacts[id].firstName} ${this.allContacts[id].lastName}, `
                                    } else {
                                        this.defaultName = this.defaultName + `${this.allContacts[id].firstName} ${this.allContacts[id].lastName}`
                                    }
                                } else {
                                    if (index != this.bulkEmail.length-1) {
                                        this.defaultName = this.defaultName + `${this.allContacts[id].email}, `
                                    } else {
                                        this.defaultName = this.defaultName + `${this.allContacts[id].email}`
                                    }
                                }
                            } else if (this.externalContacts[id]) {
                                if (this.externalContacts[id].firstName && this.externalContacts[id].lastName && this.externalContacts[id].firstName.trim() && this.externalContacts[id].lastName.trim()) {
                                    if (index != this.bulkEmail.length-1) {
                                        this.defaultName = this.defaultName + `${this.externalContacts[id].firstName} ${this.externalContacts[id].lastName}, `
                                    } else {
                                        this.defaultName = this.defaultName + `${this.externalContacts[id].firstName} ${this.externalContacts[id].lastName}`
                                    }
                                } else {
                                    if (index != this.bulkEmail.length-1) {
                                        this.defaultName = this.defaultName + `${this.externalContacts[id].email}, `
                                    } else {
                                        this.defaultName = this.defaultName + `${this.externalContacts[id].email}`
                                    }
                                }
                            }
                        })
                        this.newEmail.to = this.bulkEmail
                    }
                } else {
                    this.$store.commit('CLEAR_LEAD_ACTION')
                    this.$store.commit('CLEAR_BULK_EMAIL')
                    this.$store.commit('CLEAR_TEXT_TEMPLATE')
                    this.defaultName = ''
                }
            }
        }
    },
    computed:{
        instance(){
            return this.$store.state.instance
        },
        addTextTemplate() {
            return this.$store.state.contacts.addTextTemplate
        },
        externalContacts() {
            return this.$store.state.contacts.externalContacts
        },
        bulkEmail() {
            return this.$store.state.contacts.bulkEmail
        },
        allContacts() {
            return this.$store.state.contacts.allContacts
        },
        leadEmail() {
            return this.$store.state.contacts.leadAction
        },
        visible() {
            return this.$store.state.addNew.visible
        },
        emailModal(){
            return this.$store.state.addNew
        },
        storeMessage(){
            return this.$store.state.contacts.message
        },
		nylasAccount() {
            return this.$store.state.contacts.nylasAccount || {}
        }
    },
    data(){
        return{
            defaultName:'',
            loading:false,
            newEmail:{
                subject:'',
                to:'',
				cc:[],
				bcc:[],
                message:'',
				showSignature:true,
                attachments:[]
            },
            templates:[],

			timeout:null,
            queriedContacts:[],
            personList:[]
        }
    },

    methods:{
		loadTemplate(template){
			console.log('LOADING TEMPLATE', template)
			this.newEmail.subject = template.subject
		},
		async updateList(input) {
			try {
				let { data } = await this.$api.get(`/contacts/:instance/autocomplete?q=${input}`)
				console.log('hloooo', data)
				this.queriedContacts = data.map(({ name, email }) => {
					let obj = {}
					if (!name.trim() || !name) obj.label = email
					else obj.label = name + ' (' + email + ')'
					obj.value = email
					console.log('obj', obj)
					return obj
				})
				console.log('query', this.queriedContacts)
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
		},
        suggestContact(input){
            if (input.length < 3){
                return this.timerId = undefined
            }
            const  makeAPICall = () => {
                this.updateList(input)
            }

            if (this.timerId)  clearTimeout(this.timerId)

            this.timerId  =  setTimeout(function () {
                makeAPICall()
                this.timerId  =  undefined;
            }, 400)
        },
        req:msg=>({required:true,message:msg}),
        updateContact(value){
            this.newEmail.to = value
			this.$refs.newEmail.clearValidate(['to']);
        },
        saveTemplate(html){
            console.log('html',html)
            if (!html?.html?.trim()) return this.$message.error('Your email does not have a body')
            else {
                this.$refs.newEmail.validate(valid => {
                    if (valid) {
						let text = html.html
						this.newEmail.message = text
                        this.newEmail.attachments = html.attachments
                        this.$store.commit('SAVE_EMAIL_TEMPLATE', this.newEmail)
                    } else {
                        return false;
                    }
                });
            }
        },
        previewEmail(html){
            console.log('html',html?.html, this.storeMessage, html?.attachments)
            if (!html?.html?.trim() && !this.storeMessage.trim()) return this.$message.error('Your email does not have a body')
            else {
                this.$refs.newEmail.validate(valid => {
                    if (valid) {
                        let text = html.html
                        if (!html?.html?.trim() && this.storeMessage.trim()) text = this.storeMessage
                        this.newEmail.message = text
                        this.newEmail.attachments = html.attachments
                        this.$store.commit('PREVIEW_EMAIL', this.newEmail)
                    } else {
                        return false;
                    }
                });
            }

        },
        onClose(){
            this.newEmail = {
                subject:'',
                to:'',
				cc:[],
				bcc:[],
                message:'',
				showSignature:true,
                attachments:[]
            }
            this.$store.commit('CLOSE_ADD_NEW')
            this.$store.commit('CLEAR_MESSAGE')
        },
    },
    created(){
        // this.$api.get(`/projects/${this.instance.id}/?default=true`).then( ({data}) => {
        //     console.log('DATA', data)
        //     this.templates = data
        // })
    }
}
</script>

<style lang="scss">

</style>
