<template>
    <div>
        <a-modal :visible="imageModal.visible" :centered="true" :footer="null" @cancel="closeImageModal" :width="800">
            <img style="width:720px; height:auto; object-fit:cover" :src="imageModal.item" />
        </a-modal>
        <a-modal :visible="visible" :centered="true" :footer="null" @cancel="onClose" :width="800" :zIndex="1001">
            <div v-if="type == 'note' && Object.keys(newNote).length">
                <h5>Note Preview</h5>
                <div class="mt-4">
                    <div class="dF">
                        <div class="mr-3"  v-if="newNote.user && newNote.user != '' && users[newNote.user]">
                            <a-avatar v-if="users[newNote.user].avatar && users[newNote.user].avatar" :size="70" :src="users[newNote.user].avatar"></a-avatar>
                            <a-avatar v-else style="font-size:30px; background-color:#1070CA" shape="circle" :size="70">
                                {{users[newNote.user].firstName[0].toUpperCase()+users[newNote.user].lastName[0].toUpperCase()}}
                            </a-avatar>
                        </div>
                        <div>
                            <h3 v-if="newNote.subject">{{newNote.subject}}</h3>
                            <div style="font-size:13px" class="dF aC" v-if="newNote.user">
                                <div style="color: #818181;">Created by&nbsp;</div>
                                <!-- <div>{{newNote.user}}</div> -->
                                <div><strong>{{users[newNote.user] && users[newNote.user].firstName && users[newNote.user].lastName ? `${users[newNote.user].firstName} ${users[newNote.user].lastName}`: ''}}</strong></div>
                                <div style="color: #818181;">&nbsp;on&nbsp;{{newNote.createdAt.substring(0,10)}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5" v-if="newNote.content">
                        <div style="color:#9EA0A5">Content</div>
                        <div>{{newNote.content}}</div>
                    </div>
                    <div class="mt-3" v-if="Object.keys(contact).length">
                        <div class="dF aC"><div style="color:#9EA0A5">Primary Contact</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                        <div>{{contact.firstName && contact.lastName && contact.firstName.trim() && contact.lastName.trim() ? `${contact.firstName} ${contact.lastName}` : contact.email && contact.email != '' ? `${contact.email}` : ''}}</div>
                    </div>
                    <div class="mt-3" v-if="Object.keys(opportunity).length">
                        <div class="dF aC"><div style="color:#9EA0A5">Related To</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                        <div>{{opportunity.name ? opportunity.name : ''}}</div>
                    </div>
                </div>
            </div>
            <div v-else-if="type == 'task' && Object.keys(newTask).length">
                <h5>Task Preview</h5>
                <div class="mt-4 dF">
                    <div class="f1">
                        <div class="dF" style="width:100%">
                            <div>
                                <a-avatar class="mr-3" v-if="newTask.createdBy && newTask.createdBy.avatar && newTask.createdBy.avatar !== ''" :size="70" :src="newTask.createdBy.avatar"></a-avatar>
                                <a-avatar class="mr-3" v-else style="font-size: 30px; background-color:#1070CA" shape="circle" :size="70">
                                    <template v-if="(newTask.createdBy && typeof newTask.createdBy === 'string' || newTask.createdBy instanceof String)">{{users[newTask.createdBy].firstName[0].toUpperCase() + users[newTask.createdBy].lastName[0].toUpperCase()}}</template>
                                    <template v-else>{{newTask.createdBy && newTask.createdBy.firstName[0].toUpperCase() + newTask.createdBy.lastName[0].toUpperCase()}}</template>
                                </a-avatar>
                            </div>
                            <div style="width:100%">
                                <h3>{{newTask.title}}</h3>
                                <div class="dF" style="font-size: 13px;">
                                    <div style="color: #818181;">Created by&nbsp;</div>
                                    <div>
                                        <template v-if="(newTask.createdBy && typeof newTask.createdBy === 'string' || newTask.createdBy instanceof String)"><strong>{{users[newTask.createdBy].firstName + ' ' + users[newTask.createdBy].lastName}}</strong></template>
                                        <template v-else><strong>{{newTask.createdBy && newTask.createdBy.firstName + ' ' + newTask.createdBy.lastName}}</strong></template>
                                    </div>
                                    <div style="color: #818181;">&nbsp;on&nbsp;{{newTask.createdAt.substring(0,10)}}</div>
                                </div>
                                <div class="mt-5 mb-3" v-html="newTask.description"></div>
                                <div v-if="!newTask.files" style="color:#3F3356; width:100%; cursor:pointer; background-color:var(--off-white-dark); padding:15px; align-items:center; display:flex;"><i style="font-size:20px" class="fe fe-file-plus mr-3" />No attached files...</div>
                                <div v-else>
                                    <div v-for="item in newTask.files" :key="item.name">
                                        <!-- If image -->
                                        <div v-if="item.mime.includes('image')" class="dF mb-3 mt-3" style="align-items:start">
                                            <div class="relative dF" style="height: auto; width: auto;">
                                                <img style="max-width:100%; max-height:250px" :src="item.url" />
                                                <div class="absolute dF w-full" style="justify-content: flex-end; height: auto;">
                                                    <div class="dF" style="background-color:var(--off-white-dark);">
                                                        <div style="padding: 15px;">
                                                            <a-icon class="icon-button" type="eye" style="font-size:20px" @click="previewImage(item.url)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- If pdf/doc -->
                                        <div v-else-if="item.mime.includes('pdf') || item.mime.includes('doc')" style="padding:15px; background-color:var(--off-white-dark); align-items: center" class="mb-3 mt-3 w-full dF">
                                            <div class="dF jSB w-full" style="align-items: center;">
                                                <div>
                                                    <a-icon v-if="item.mime.includes('pdf')" class="mr-3" style="color:var(--danger)" type="file-pdf" />
                                                    <a-icon v-if="item.mime.includes('doc')" class="mr-3" style="color:var(--blue)" type="file-word" />
                                                    {{item.name}}
                                                </div>
                                                <div class="dF" style="justify-content:flex-end">
                                                    <a :href="item.url" target="_blank"><a-icon style="font-size:20px" type="eye" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else style="padding:15px; background-color:var(--off-white-dark); align-items: center" class="mb-3 mt-3 w-full dF">
                                            <div class="dF jSB w-full" style="align-items: center;">
                                                <div>
                                                    <a-icon v-if="item.mime.includes('excel')" class="mr-3" style="color:var(--teal)" type="file-excel" />
                                                    <a-icon v-else class="mr-3" type="file" />
                                                    {{item.name}}
                                                </div>
                                                <div class="dF" style="justify-content:flex-end">
                                                    <a :href="item.url" target="_blank"><a-icon style="font-size:20px" type="eye" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width:200px; margin-left:45px">
                        <div v-if="newTask.contact || newTask.opportunity">
                            <div class="mb-2" style="color:#9EA0A5">{{newTask.relatedType == 'contact' ? 'Primary Contact' : 'Opportunity'}}</div>
                            <div class="dF" style="align-items:center">
                                <template v-if="newTask.relatedType === 'contact'">
                                    <a-avatar class="mr-2" v-if="newTask.contact && newTask.contact.avatar" :src="newTask.contact.avatar" />
                                    <a-avatar class="mr-2" v-else style="color:white; background-color: #1070CA">{{newTask.contact.firstName && newTask.contact.lastName && newTask.contact.firstName.trim() && newTask.contact.lastName.trim() ? newTask.contact.firstName[0].toUpperCase() + newTask.contact.lastName[0].toUpperCase() : newTask.contact.email[0].toUpperCase()}}</a-avatar>
                                    {{newTask.contact.firstName && newTask.contact.lastName && newTask.contact.firstName.trim() && newTask.contact.lastName.trim() ? newTask.contact.firstName + ' ' + newTask.contact.lastName : newTask.contact.email}}
                                </template>
                                <template v-if="newTask.relatedType === 'opportunity'">
                                    <a-avatar class="mr-2" style="color:white; background-color: #00c48b">{{ newTask.opportunity && newTask.opportunity.name ? newTask.opportunity.name[0].toUpperCase():''}}</a-avatar>
                                    {{newTask.opportunity && newTask.opportunity.name ? newTask.opportunity.name:''}}
                                </template>
                            </div>
                        </div>
                        <div v-if="newTask.priority" class="mt-4">
                            <div class="mb-2" style="color:#9EA0A5">Priority Level</div>
                            <div style="width:100%; color:#FFF; border-radius:4px; text-align:center" :style="newTask.priority == 'high' ? 'background-color:var(--danger)': newTask.priority == 'medium' ? 'background-color:var(--orange)' : newTask.priority == 'low' ? 'background-color:var(--teal)' : ''">{{newTask.priority == 'high' ? 'HIGH PRIORITY' : newTask.priority == 'medium' ? 'MED PRIORITY' : newTask.priority == 'low' ? 'LOW PRIORITY':''}}</div>
                        </div>
                        <div v-if="newTask.type" class="mt-4">
                            <div class="mb-2" style="color:#9EA0A5">Task Type</div>
                            <div>{{getTaskType(newTask.type)}}</div>
                        </div>
                        <div v-if="newTask.assignTo" class="mt-4">
                            <div class="mb-2" style="color:#9EA0A5">Assignee(s)</div>
                            <div class="dF aC">
                                <div v-for="member in newTask.assignTo" :key="member.id">
                                    <a-tooltip class="mr-1" overlayClassName="change-tooltip-color">
                                        <template slot="title">
                                            {{member.firstName + ' ' + member.lastName}}
                                        </template>
                                        <a-avatar v-if="member.avatar && member.avatar.trim()" :src="member.avatar" />
                                        <a-avatar style="background-color:#1070CA" v-else shape="circle">{{member.firstName[0].toUpperCase() + member.lastName[0].toUpperCase()}}</a-avatar>
                                    </a-tooltip>
                                </div>
                            </div>
                        </div>
                        <div v-if="newTask.dueDate" class="mt-4">
                            <div class="mb-2" style="color:#9EA0A5">Due Date</div>
                            <div>{{convertDate(newTask.dueDate)}}</div>
                        </div>
                    </div>
                </div>
                <div style="width: calc(100% - 200px - 45px);">
                    <div>
                        <h5>Comments ({{newTask.comments ? newTask.comments.length:''}})</h5>
                        <hr>
                    </div>
                    <div class="dF" v-for="(comment, commentI) in newTask.comments" :key="commentI" :style="commentI != 0 ? 'margin-top:25px':''">
                        <div>
                            <a-avatar v-if="users[comment.user] && users[comment.user].avatar && users[comment.user].avatar !== ''" :size="50" :src="users[comment.user].avatar" />
                            <a-avatar style="background-color:#1070CA" :size="50" v-else>{{users[comment.user].firstName.substring(0,1)}}</a-avatar>
                        </div>
                        <div class="ml-3" style="flex:1">
                            <div class="dF w-full">
                                <strong>{{users[comment.user] ? users[comment.user].firstName+ ' ' + users[comment.user].lastName:''}}</strong>
                                <p class="ml-5" style="color:#818181">{{comment.createdAt.substring(0,10)}}</p>
                            </div>
                            <div v-html="comment.content"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="type == 'appointment' && Object.keys(newAppointment).length">
                <h5>Appointment Preview</h5>
                <div class="mt-4">
                    <div class="dF">
                        <div class="f1">
                            <h3>{{newAppointment.title}}</h3>
                            <div style="font-size:15px">{{getAppointmentTime(newAppointment.date, newAppointment.startTime, newAppointment.endTime)}}</div>
                            <div class='mt-5'>
                                <div v-if="newAppointment.location && newAppointment.location != ''">
                                    <div class="mb-2" style="color:#9EA0A5">Location</div>
                                    <div>{{newAppointment.location}}</div>
                                </div>
                                <div class="mt-4" v-if="newAppointment.link && newAppointment.link != ''">
                                    <div class="mb-2" style="color:#9EA0A5">Link</div>
									<div>
										<a :href="newAppointment.link || ''" target="_blank">{{newAppointment.link || ''}}</a>
									</div>
                                </div>
                                <div class="mt-4" v-if="newAppointment.reminderType != 'none'">
                                    <a-checkbox :checked="newAppointment.reminderType != 'none'">Setup a reminder</a-checkbox>
                                    <div class="mt-2" v-if="newAppointment.reminderType != 'onetime'">{{reminder[newAppointment.reminderType]}}</div>
                                    <div class="mt-2" v-else>{{getReminder(newAppointment.reminderOn)}}</div>
                                </div>
                                <div class="mt-4" v-if="newAppointment.notes && newAppointment.notes != '' ">
                                    <div class="mb-2" style="color:#9EA0A5">Notes</div>
                                    <div>{{newAppointment.notes}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-left:45px; width:200px">
                            <div v-if="newAppointment.contact || newAppointment.opportunity">
                                <div class="dF aC mb-2"><div style="color:#9EA0A5">{{newAppointment.contact ? 'Primary Contact':'Opportunity'}}</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <template v-if="newAppointment.relatedType === 'contact'">
                                    <a-avatar class="mr-2" v-if="newAppointment.contact && newAppointment.contact.avatar" :src="newAppointment.contact.avatar" />
                                    <a-avatar class="mr-2" v-else style="color:white; background-color: #1070CA">{{newAppointment.contact.firstName && newAppointment.contact.lastName && newAppointment.contact.firstName.trim() && newAppointment.contact.lastName.trim() ? newAppointment.contact.firstName[0].toUpperCase() + newAppointment.contact.lastName[0].toUpperCase() : newAppointment.contact.email[0].toUpperCase()}}</a-avatar>
                                    {{newAppointment.contact.firstName && newAppointment.contact.lastName && newAppointment.contact.firstName.trim() && newAppointment.contact.lastName.trim() ? newAppointment.contact.firstName + ' ' + newAppointment.contact.lastName : newAppointment.contact.email}}
                                </template>
                                <template v-if="newAppointment.relatedType === 'opportunity'">
                                    <a-avatar class="mr-2" style="color:white; background-color: #00c48b">{{ newAppointment.opportunity && newAppointment.opportunity.name ? newAppointment.opportunity.name[0].toUpperCase():''}}</a-avatar>
                                    {{newAppointment.opportunity && newAppointment.opportunity.name ? newAppointment.opportunity.name:''}}
                                </template>
                            </div>
                            <div class="mt-4" v-if="newAppointment.type">
                                <div class="dF aC mb-2"><div style="color:#9EA0A5">Appointment Type</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <div>{{getAppointmentType(newAppointment.type)}}</div>
                            </div>
                            <div v-if="newAppointment.assignTo" class="mt-4">
                                <div class="dF aC mb-2"><div style="color:#9EA0A5">Assign To</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <div class="dF aC">
                                    <div v-for="member in newAppointment.assignTo" :key="member.id">
                                        <a-tooltip class="mr-1" overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                {{member.firstName + ' ' + member.lastName}} ({{ member.email }})
                                            </template>
                                            <a-avatar v-if="member.avatar && member.avatar.trim()" :src="member.avatar" />
                                            <a-avatar style="background-color:#1070CA" v-else shape="circle">{{member.firstName[0].toUpperCase() + member.lastName[0].toUpperCase()}}</a-avatar>
                                        </a-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <a-row class="mt-5 mb-4">
                        <a-col :span="8" v-if="newAppointment.date">
                            <div class="dF aC mb-2"><div style="color:#9EA0A5">Date</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                            <div>{{convertDate(newAppointment.date)}}</div>
                        </a-col>
                        <a-col :span="8" v-if="newAppointment.startTime">
                            <div class="dF aC mb-2"><div style="color:#9EA0A5">Start Time</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                            <div>{{convertTime(newAppointment.startTime)}}</div>
                        </a-col>
                        <a-col :span="8" v-if="newAppointment.endTime">
                            <div class="dF aC mb-2"><div style="color:#9EA0A5">End Time</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                            <div>{{convertTime(newAppointment.endTime)}}</div>
                        </a-col>
                    </a-row> -->

                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import index from '../layout/SubBar/index.vue'
import moment from 'moment'
export default {
  components: { index },
    computed:{
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        appointmentTypes() {
            return [
				{ name: 'Virtual Meeting', id: 'virtualmeeting' },
				{ name: 'Phone Meeting', id: 'phonemeeting' },
				{ name: 'Sales Center', id: 'salescentre' }
			]
        },
        user() {
            return this.$store.user.user
        },
        taskTypes() {
            if (this.$store.state.contacts?.allSettings?.app?.options?.taskType?.length) {
                return this.$store.state.contacts.allSettings.app.options.taskType
            } else {
                return [
                    {name:'To-do', id:'todo'},
                    {name:'Call', id:'call'},
                    {name:'Follow up', id:'followup'}
                ]
            }
        },
        users() {
            let users = this.$store.state.contacts.users
            let obj = {}
            users.forEach(x => {
                obj[x.id] = x
            })
            return obj
        },
        visible() {
            return this.$store.state.contacts.previewModal.visible
        },
        object() {
            return this.$store.state.contacts.previewModal.object
        },
        type() {
            return this.$store.state.contacts.previewModal.type
        },
        allContacts() {
            return this.$store.state.contacts.allContacts
        },
        externalContacts() {
            return this.$store.state.contacts.externalContacts
        },
        opportunities() {
            return this.$store.state.contacts.opportunities
        }
    },
    watch:{
        visible:{
            handler(val) {
                if (val) {
                    if (this.$route.path.includes('/leads') && this.$route.params && this.$route.params.id && this.$route.params.id != '') {
                        if (this.allContacts[this.$route.params.id]) {
                            this.contact = JSON.parse(JSON.stringify(this.allContacts[this.$route.params.id]))
                        }
                        else if (this.externalContacts[this.$route.params.id]) {
                            this.contact = JSON.parse(JSON.stringify(this.externalContacts[this.$route.params.id]))
                        }
                    }
                    if (this.$route.path.includes('/opportunities') && this.$route.params && this.$route.params.id && this.$route.params.id != '') {
                        let find = this.opportunities.find(x => x.id == this.$route.params.id)
                        if (find) this.opportunity = JSON.parse(JSON.stringify(find))
                    }
                    if (this.type == 'note') {
                        this.newNote = JSON.parse(JSON.stringify(this.object))
                    } else if (this.type == 'task') {
                        this.newTask = JSON.parse(JSON.stringify(this.object))
                    } else if (this.type == 'appointment') {
                        this.newAppointment = JSON.parse(JSON.stringify(this.object))
                    }
                }
            }
        }
    },
    data() {
        return{
            newNote:{},
            newTask:{},
            newAppointment:{},
            contact:{},
            opportunity:{},
            imageModal:{
                visible:false,
                item:''
            },
            reminder : {
                "r15m":'15 Minutes',
                "r30m":'30 Minutes',
                "r1h":'1 Hour',
                "r2h":'2 Hours',
                "r6h":'6 Hours',
                "r12h":'12 Hours',
                "r1d":'1 Day',
                "r2d":'2 Days',
                "r1w":'1 Week',
                "onetime":'One-time'
            }
        }
    },
    methods:{
        moment,
        getReminder(num) {
            if (num == 0) return 'N/A'
            else {
                let date = moment(num).format('dddd, MMM DD, YYYY  |  hh:mm')
                return date
            }
        },
        getAppointmentType(typeId) {
            let find = this.appointmentTypes.find(x => x.id == typeId)
            if (find) return find.name
            else return ''
        },
        getAppointmentTime(date,start,end){
            if (date != 0 && start != 0 && end != 0 && date != undefined && start != undefined && end != undefined) {
                let appDate = moment(date).format('dddd, MMM DD, YYYY')
                let startTime = moment(start).format('hh:mm')
                let endTime = moment(end).format('hh:mm')
                return `${appDate}  .  ${startTime} - ${endTime}`
            } else return 'N/A'
        },
        previewImage(url) {
            this.imageModal.visible = true
            this.imageModal.item = url
        },
        closeImageModal() {
            this.imageModal.visible = false
            this.imageModal.item = ''
        },
        convertTime(num) {
            if (num == 0 || num == undefined) return 'N/A'
            else {
                let date = new Date(num)
                let hour = date.getHours().toString()
                if (hour.length == 1) hour = '0'+hour
                let minute = date.getMinutes().toString()
                if (minute.length == 1) minute = '0'+minute
                return `${hour}:${minute}`
            }
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        getTaskType(taskId) {
            let find = this.taskTypes.find(x => x.id == taskId)
            if (find) {
                return find.name
            }
            else return ''
        },
        onClose() {
            this.$store.commit('CLOSE_PREVIEW_MODAL')
        }
    }
}
</script>

<style scoped>
.icon-button:hover{
    color:var(--orange) !important;
}

a {
	color: var(--primary);
	text-decoration: none;
}
</style>
